import React, { useState, useEffect } from 'react';

//services
import localStorageService from '../services/local_storage';

//NAV TOP SVG
import { ReactComponent as CasinoSvg } from '../svg/casino.svg';
import { ReactComponent as SportsSvg } from '../svg/sports.svg';

//NAV GRPOUP SVG
import { ReactComponent as PromotionsSvg } from '../svg/promotions.svg';
import { ReactComponent as ReferSvg } from '../svg/refer.svg';
import { ReactComponent as RedeemSvg } from '../svg/redeem.svg';
// import { ReactComponent as FreePlaySvg } from '../svg/free-play.svg';

//NAV BOTTOM SVG
import { ReactComponent as VipSvg } from '../svg/vip.svg';
import { ReactComponent as LiveSupportSvg } from '../svg/live-support.svg';
import globImg from '../svg/globus.svg';
import { ReactComponent as ActiveSvg } from '../svg/active.svg';

//CASINO GAMES SVG
import { ReactComponent as GamesSvg } from '../svg/games.svg';
import { ReactComponent as PopularSvg } from '../svg/popular.svg';
import { ReactComponent as NewGamesSvg } from '../svg/new-games.svg';
import { ReactComponent as SlotsSvg } from '../svg/slots.svg';
import { ReactComponent as BonusSvg } from '../svg/bonus.svg';
import { ReactComponent as LiveCasinoSvg } from '../svg/live-casino.svg';
import { ReactComponent as GamesShowsSvg } from '../svg/games-shows.svg';
import { ReactComponent as RouletteSvg } from '../svg/roulette.svg';
import { ReactComponent as BlackjackSvg } from '../svg/blackjack.svg';
import { ReactComponent as BaccaratSvg } from '../svg/baccarat.svg';

//SPORTS GAMES SVG
import { ReactComponent as SoccerSvg } from '../svg/soccer.svg';
import { ReactComponent as BasketballSvg } from '../svg/basketball.svg';
import { ReactComponent as TennisSvg } from '../svg/tennis.svg';
import { ReactComponent as CounterStrikeSvg } from '../svg/counter-strike.svg';
import { ReactComponent as FifaSvg } from '../svg/fifa.svg';
import { ReactComponent as BaseballSvg } from '../svg/baseball.svg';
import { ReactComponent as IceHockeySvg } from '../svg/ice-hockey.svg';
import { ReactComponent as CricketSvg } from '../svg/cricket.svg';
import { ReactComponent as MmaSvg } from '../svg/mma.svg';
import { ReactComponent as TableTennisSvg } from '../svg/table-tennis.svg';

//MORE SVG
import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';
import { ReactComponent as MenuSvg } from '../svg/menu.svg';
import { ReactComponent as SearchSvg } from '../svg/search.svg';
import { ReactComponent as ChatSvg } from '../svg/chat.svg';
import { ReactComponent as RewardsSvg } from '../svg/rewards.svg';

//BET COUNTER CMP
import BetCounter from './BetCounter';


function Navbar({ isNavOpened, toggleMenu, toggleSearch, toggleChat, isMenuSelected, isSearchSelected, isChatSelected, closeAllNavOptions }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredSecond, setIsHoveredSecond] = useState(false);
    const [isHoveredLang, setIsHoveredLang] = useState(false);
    const [activeLang, setActiveLang] = useState('English'); // State to track active language
    const [isCasinoDropDownOpened, setIsCasinoDropDownOpened] = useState(true)
    const [isSportsDropDownOpened, setIsSportsDropDownOpened] = useState(false)
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [loggedinUser, setLoggedinUser] = useState(null)


    useEffect(() => {
        const user = localStorageService.readFromLocalStorage("user")
        if (user) {
            setLoggedinUser(user)
        }

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 800);
            closeAllNavOptions()
        };

        // Add event listener to check screen size on resize
        window.addEventListener('resize', handleResize);

        // Initial check on component mount
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [closeAllNavOptions]);

    const handleLangClick = (lang) => {
        setActiveLang(lang);
    };


    const toggleCasinoDropDown = () => {
        setIsCasinoDropDownOpened(!isCasinoDropDownOpened);
        if (!isCasinoDropDownOpened && isSportsDropDownOpened) {
            setIsSportsDropDownOpened(false);
        }
    };

    const toggleSportsDropDown = () => {
        setIsSportsDropDownOpened(!isSportsDropDownOpened);
        if (!isSportsDropDownOpened && isCasinoDropDownOpened) {
            setIsCasinoDropDownOpened(false);
        }
    };

    if (isSmallScreen) {
        return (<>
            <div className='nav-gap'></div>
            <div className='nav-gap-top'></div>
            <div className='mobile-navbar'>
                <div onClick={toggleMenu} className={isMenuSelected ? 'active' : ''}>
                    <MenuSvg className="svg-icon" />
                    <p>MENU</p>
                </div>
                <div onClick={toggleSearch} className={isSearchSelected ? 'active' : ''}>
                    <SearchSvg className="svg-icon" />
                    <p>SEARCH</p>
                </div>
                <a href='https://roobet.com/casino'>
                    <CasinoSvg className="svg-icon" />
                    <p>CASINO</p>
                </a>
                <a href='https://roobet.com/sports'>
                    <SoccerSvg className="svg-icon" />
                    <p>SPORTS</p>
                </a>
                <div onClick={() => { toggleChat() }} className={isChatSelected ? 'active' : ''}>
                    <ChatSvg className="svg-icon" />
                    <p>CHAT</p>
                </div>
            </div>
        </>
        )
    }

    // DESKTOP SCREEN MEDIA QUERY
    if (!isNavOpened) {
        return (
            <div className="navbar">
                {/* NAV TOP - CASINO*/}
                <div className="home-side-nav-item">
                    <div className='nav-main-btn-container' style={{ backgroundColor: isHovered ? '#463E7A' : '' }}>
                        <div className='nav-main-btn'>
                            <CasinoSvg className="svg-icon" />
                        </div>
                    </div>
                    <div className='nav-hover-falldown-menu'>
                        <div onMouseEnter={() => { setIsHovered(true) }} onMouseLeave={() => { setIsHovered(false) }} className='nav-hover-falldown-menu-container'>
                            <a href='https://roobet.com/casino?category=roobet-games' className='first'>
                                <GamesSvg className="svg-icon" />
                                <p>Roobet Games</p>
                            </a>
                            <a href='https://roobet.com/casino?category=popular'>
                                <PopularSvg className="svg-icon" />
                                <p>Popular</p>
                            </a>
                            <a href='https://roobet.com/tag/new-games'>
                                <NewGamesSvg className="svg-icon" />
                                <p>New Games</p>
                            </a>
                            <a href='https://roobet.com/casino?category=slots'>
                                <SlotsSvg className="svg-icon" />
                                <p>Slots</p>
                            </a>
                            <a href='https://roobet.com/tag/bonus-buys'>
                                <BonusSvg className="svg-icon" />
                                <p>Bonus Buys</p>
                            </a>
                            <a href='https://roobet.com/casino?category=livecasino'>
                                <LiveCasinoSvg className="svg-icon" />
                                <p>Live Casino</p>
                            </a>
                            <a href='https://roobet.com/casino?category=gameshows'>
                                <GamesShowsSvg className="svg-icon" />
                                <p>Games Shows</p>
                            </a>
                            <a href='https://roobet.com/tag/roulette'>
                                <RouletteSvg className="svg-icon" />
                                <p>Roulette</p>
                            </a>
                            <a href='https://roobet.com/tag/blackjack'>
                                <BlackjackSvg className="svg-icon" />
                                <p>Blackjack</p>
                            </a>
                            <a href='https://roobet.com/tag/baccarat' className='last'>
                                <BaccaratSvg className="svg-icon" />
                                <p>Baccarat</p>
                            </a>
                        </div>
                    </div>
                </div>
                {/* NAV TOP - SPORTS*/}
                <div className="home-side-nav-item">
                    <div className='nav-main-btn-container' style={{ backgroundColor: isHoveredSecond ? '#463E7A' : '' }}>
                        <div className='nav-main-btn'>
                            <SportsSvg className="svg-icon" /></div>
                    </div>
                    <div className='nav-hover-falldown-menu'>
                        <div onMouseEnter={() => { setIsHoveredSecond(true) }} onMouseLeave={() => { setIsHoveredSecond(false) }} className='nav-hover-falldown-menu-container'>
                            <a href='https://roobet.com/sports?bt-path=%2Fsoccer-1' className='first'>
                                <SoccerSvg className="svg-icon" />
                                <p>Soccer</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Fbasketball-2'>
                                <BasketballSvg className="svg-icon" />
                                <p>Basketball</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Ftennis-5'>
                                <TennisSvg className="svg-icon" />
                                <p>Tennis</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Fcounter-strike-109'>
                                <CounterStrikeSvg className="svg-icon" />
                                <p>Counter-Strike</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Ffifa-300'>
                                <FifaSvg className="svg-icon" />
                                <p>FIFA</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Fbaseball-3'>
                                <BaseballSvg className="svg-icon" />
                                <p>Baseball</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Fice-hockey-4'>
                                <IceHockeySvg className="svg-icon" />
                                <p>Ice-Hockey</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Fcricket-21'>
                                <CricketSvg className="svg-icon" />
                                <p>Cricket</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Fmma-117'>
                                <MmaSvg className="svg-icon" />
                                <p>MMA</p>
                            </a>
                            <a href='https://roobet.com/sports?bt-path=%2Ftable-tennis-20' className='last'>
                                <TableTennisSvg className="svg-icon" />
                                <p>Table Tennis</p>
                            </a>
                        </div>
                    </div>
                </div>
                {loggedinUser && <a href='https://roobet.com/rewards' className="home-side-nav-item bottom">
                    <RewardsSvg className="svg-icon" />
                    <div className='tool-tip'>Rewards</div>
                </a>}
                {/* NAV GROUP*/}
                <div className='nav-bar-group-container'>
                    <a href='https://promotions.roobet.com/' className="home-side-nav-item first">
                        <PromotionsSvg className="svg-icon" />
                        <div className='tool-tip'>Promotions</div>
                    </a>
                    <a href='https://roobet.com/?modal=affiliate&tab=referFriends' className="home-side-nav-item">
                        <ReferSvg className="svg-icon" />
                        <div className='tool-tip'>Refer & Earn</div>
                    </a>
                    <a href='https://roobet.com/?modal=redeem' className="home-side-nav-item last">
                        <RedeemSvg className="svg-icon" />
                        <div className='tool-tip'>Redeem</div>
                    </a>
                    {/* <a href='https://roobet.fun/' className="home-side-nav-item last">
                        <FreePlaySvg className="svg-icon" />
                        <div className='tool-tip'>Free Play</div>
                    </a> */}
                </div>
                {/* NAV BOTTOM*/}
                <a href='https://roobet.com/vip' className="home-side-nav-item bottom">
                    <VipSvg className="svg-icon" />
                    <div className='tool-tip'>VIP Club</div>
                </a>
                <div className="home-side-nav-item bottom">
                    <LiveSupportSvg className="svg-icon" />
                    <div className='tool-tip'>Live Support</div>
                </div>
                <div className="home-side-nav-item lang">
                    <div className='nav-main-btn-container' style={{ backgroundColor: isHoveredLang ? '#463E7A' : '' }}>
                        <div className='nav-main-btn' style={{ backgroundColor: isHoveredLang ? '#463E7A' : '' }}>
                            <img src={globImg} alt="English" /></div>
                    </div>
                    <div className='nav-hover-falldown-menu'>
                        <div onMouseEnter={() => { setIsHoveredLang(true) }} onMouseLeave={() => { setIsHoveredLang(false) }} className='nav-hover-falldown-menu-container'>
                            <div className={`first ${activeLang === 'English' ? 'active' : ''}`} onClick={() => handleLangClick('English')}>
                                <p>English</p>
                                {activeLang === 'English' && <ActiveSvg className="svg-icon" />}
                            </div>

                            <div onClick={() => handleLangClick('Español')} className={`${activeLang === 'Español' ? 'active' : ''}`}>
                                <p>Español</p>
                                {activeLang === 'Español' && <ActiveSvg className="svg-icon" />}
                            </div>

                            <div onClick={() => handleLangClick('Português')} className={`${activeLang === 'Português' ? 'active' : ''}`}>
                                <p>Português</p>
                                {activeLang === 'Português' && <ActiveSvg className="svg-icon" />}
                            </div>

                            <div onClick={() => handleLangClick('Français')} className={`${activeLang === 'Français' ? 'active' : ''}`}>
                                <p>Français</p>
                                {activeLang === 'Français' && <ActiveSvg className="svg-icon" />}
                            </div>

                            <div onClick={() => handleLangClick('Srpski')} className={`${activeLang === 'Srpski' ? 'active' : ''}`}>
                                <p>Srpski</p>
                                {activeLang === 'Srpski' && <ActiveSvg className="svg-icon" />}
                            </div>

                            <div onClick={() => handleLangClick('Türkçe')} className={`${activeLang === 'Türkçe' ? 'active' : ''}`}>
                                <p>Türkçe</p>
                                {activeLang === 'Türkçe' && <ActiveSvg className="svg-icon" />}
                            </div>

                            <div className={`last ${activeLang === 'فارسی' ? 'active' : ''}`} onClick={() => handleLangClick('فارسی')}>
                                <p>فارسی</p>
                                {activeLang === 'فارسی' && <ActiveSvg className="svg-icon" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    } else {
        return (
            <div className="navbar">
                <div className="home-side-nav-item-opened">
                    <div
                        onClick={toggleCasinoDropDown}
                        className={`home-side-nav-item-opened-btn ${isCasinoDropDownOpened ? "active" : ""}`}
                    >
                        <div>
                            <CasinoSvg className="svg-icon" />
                            <p>Casino</p>
                        </div>
                        <div className='chevron-container'>
                            <ChevronDownSvg className="svg-icon" />
                        </div>
                    </div>
                    <div className={`falldown-menu ${isCasinoDropDownOpened ? "active" : ""}`}>
                        <a href="https://roobet.com/casino?category=roobet-games">
                            <GamesSvg className="svg-icon" />
                            <p>Roobet Games</p>
                        </a>
                        <a href='https://roobet.com/casino?category=popular'>
                            <PopularSvg className="svg-icon" />
                            <p>Popular</p>
                        </a>
                        <a href='https://roobet.com/tag/new-games'>
                            <NewGamesSvg className="svg-icon" />
                            <p>New Games</p>
                        </a>
                        <a href='https://roobet.com/casino?category=slots'>
                            <SlotsSvg className="svg-icon" />
                            <p>Slots</p>
                        </a>
                        <a href='https://roobet.com/tag/bonus-buys'>
                            <BonusSvg className="svg-icon" />
                            <p>Bonus Buys</p>
                        </a>
                        <a href='https://roobet.com/casino?category=livecasino'>
                            <LiveCasinoSvg className="svg-icon" />
                            <p>Live Casino</p>
                            <span className='live-tag'>LIVE</span>
                        </a>
                        <a href='https://roobet.com/casino?category=gameshows'>
                            <GamesShowsSvg className="svg-icon" />
                            <p>Games Shows</p>
                        </a>
                        <a href='https://roobet.com/tag/roulette'>
                            <RouletteSvg className="svg-icon" />
                            <p>Roulette</p>
                        </a>
                        <a href='https://roobet.com/tag/blackjack'>
                            <BlackjackSvg className="svg-icon" />
                            <p>Blackjack</p>
                        </a>
                        <a href='https://roobet.com/tag/baccarat' className='last'>
                            <BaccaratSvg className="svg-icon" />
                            <p>Baccarat</p>
                        </a>
                    </div>
                </div>

                <div className="home-side-nav-item-opened">
                    <div
                        onClick={toggleSportsDropDown}
                        className={`home-side-nav-item-opened-btn ${isSportsDropDownOpened ? "active" : ""}`}
                    >
                        <div>
                            <SportsSvg className="svg-icon" />
                            <p>Sportsbook</p>
                        </div>
                        <div className='chevron-container'>
                            <ChevronDownSvg className="svg-icon" />
                        </div>
                    </div>
                    <div className={`falldown-menu ${isSportsDropDownOpened ? "active" : ""}`}>
                        <a href="https://roobet.com/sports?bt-path=%2Fsoccer-1">
                            <SoccerSvg className="svg-icon" />
                            <p>Soccer</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fbasketball-2">
                            <BasketballSvg className="svg-icon" />
                            <p>Basketball</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Ftennis-5">
                            <TennisSvg className="svg-icon" />
                            <p>Tennis</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fcounter-strike-109">
                            <CounterStrikeSvg className="svg-icon" />
                            <p>Counter-Strike</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Ffifa-300">
                            <FifaSvg className="svg-icon" />
                            <p>FIFA</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fbaseball-3">
                            <BaseballSvg className="svg-icon" />
                            <p>Baseball</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fice-hockey-4">
                            <IceHockeySvg className="svg-icon" />
                            <p>Ice-Hockey</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fcricket-21">
                            <CricketSvg className="svg-icon" />
                            <p>Cricket</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fmma-117">
                            <MmaSvg className="svg-icon" />
                            <p>MMA</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Ftable-tennis-20" className='last'>
                            <TableTennisSvg className="svg-icon" />
                            <p>Table Tennis</p>
                        </a>
                    </div>
                </div>

                {loggedinUser && <a href='https://roobet.com/rewards' className="home-side-nav-item bottom opened">
                    <RewardsSvg className="svg-icon" />
                    <p>Rewards</p>
                </a>}

                <div className='nav-bar-group-container opened'>
                    <a href='https://promotions.roobet.com/' className="home-side-nav-item first">
                        <PromotionsSvg className="svg-icon" />
                        <p>Promotions</p>
                    </a>
                    <a href='https://roobet.com/?modal=affiliate&tab=referFriends' className="home-side-nav-item">
                        <ReferSvg className="svg-icon" />
                        <p>Refer & Earn</p>
                    </a>
                    <a href='https://roobet.com/?modal=redeem' className="home-side-nav-item last">
                        <RedeemSvg className="svg-icon" />
                        <p>Redeem</p>
                    </a>
                    {/* <a href='https://roobet.fun/' className="home-side-nav-item last">
                        <FreePlaySvg className="svg-icon" />
                        <p>Free Play</p>
                    </a> */}
                </div>

                <a href='https://roobet.com/vip' className="home-side-nav-item bottom opened">
                    <VipSvg className="svg-icon" />
                    <p>VIP Club</p>
                </a>
                <div className="home-side-nav-item bottom opened">
                    <LiveSupportSvg className="svg-icon" />
                    <p>Live Support</p>
                </div>

                <div className="home-side-nav-item bottom opened lang">
                    <div>
                        <img src={globImg} alt="English" />
                        <p>{activeLang}</p>
                    </div>
                    <div className='chevron-container'>
                        <ChevronDownSvg className="svg-icon" />
                    </div>
                </div>

                <BetCounter />

            </div >
        )
    }

}

export default Navbar;